<template>
  <DirectionBindingList/>
</template>

<script>
import DirectionBindingList from "@/components/app_setting/direction_binding/DirectionBindingList";

export default {
  name: "CausePage",
  components: {
    DirectionBindingList
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Direction Binding'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>